@import url(https://fonts.googleapis.com/css?family=Noto+Sans+SC);
@import url(https://fonts.googleapis.com/css?family=Fjalla+One);
@import url(https://fonts.googleapis.com/css?family=Fjalla+One|Montserrat);
@import url(https://fonts.googleapis.com/css?family=Kanit:200);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

*{
  font-family: 'Noto Sans SC', sans-serif;
  white-space: normal !important;
  white-space: initial !important;
}
ul{
  padding: 0
}
@media screen and (max-width: 992px) {
  * {
    font-size: 13px;
  }
}
body{
  min-height: 100%;
}
img{
  max-width: 100%;
}

.text-content {
  white-space: pre-line !important;
}

.ant-collapse .ant-collapse-item .ant-collapse-header .anticon {
  left: auto;
  left: initial;
  right: 16px;
}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #cccccc
}
::-moz-placeholder { /* Firefox 19+ */
  color: #cccccc;
}
:-ms-input-placeholder { /* IE 10+ */
  color:#cccccc;
}
:-moz-placeholder { /* Firefox 18- */
  color: #cccccc;
}


