@import url('https://fonts.googleapis.com/css?family=Noto+Sans+SC');
@import url('https://fonts.googleapis.com/css?family=Fjalla+One');
@import url('https://fonts.googleapis.com/css?family=Fjalla+One|Montserrat');
@import url('https://fonts.googleapis.com/css?family=Kanit:200');

*{
  font-family: 'Noto Sans SC', sans-serif;
  white-space: initial !important;
}
ul{
  padding: 0
}
@media screen and (max-width: 992px) {
  * {
    font-size: 13px;
  }
}
body{
  min-height: 100%;
}
img{
  max-width: 100%;
}

.text-content {
  white-space: pre-line !important;
}

.ant-collapse .ant-collapse-item .ant-collapse-header .anticon {
  left: initial;
  right: 16px;
}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #cccccc
}
::-moz-placeholder { /* Firefox 19+ */
  color: #cccccc;
}
:-ms-input-placeholder { /* IE 10+ */
  color:#cccccc;
}
:-moz-placeholder { /* Firefox 18- */
  color: #cccccc;
}

